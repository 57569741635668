import HttpHandler from "@/handler/HttpHandler";
import HttpConstant from "@/const/HttpConstant";


export default class tvRoom_Index {
    constructor() {
        this.http = new HttpHandler();
    }

    mediaLibraryAll(data) {
        return this.http.get(`/api/mediaLibrary/sour`, data, HttpConstant.APPLICATION_JSON_REQ);
    }
}